.products-display {
  display: flex;
  flex-direction: column;
}
.product-display-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
    padding: 20px;
    padding-top: 8rem;
    background-image: url('../../../assets/images/homePage/zovGlobal.jpg'); /* Chemin vers votre image de fond */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
  }

  .pagination-container {
    margin-top: auto; // Pousse le conteneur vers le bas
    padding: 20px; // Ajoute un peu d'espace autour de la pagination
    text-align: center; // Centre le composant Pagination
    align-items: center;
  }
  
  .product-card {
    width: 25%; // ou la largeur que vous préférez
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    max-height: 35vh;
    border-radius: 10px;
    transition: transform 0.3s ease;
    cursor: pointer;
  
    &:hover {
      transform: translateY(-5px);
    }
  
    .product-image {
      width: 100%;
      height: 30vh;
      display: block;
    }
  
    .product-info {
      padding: 1px;
      background-color: white;
  
      .product-title {
        color: #333;
        font-size: 1.2em;
      }
  
    }
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    max-width: 900px; /* Limite la largeur du contenu de la modale */
    max-height: 900px; /* Limite la hauteur du contenu de la modale */
    padding: 20px;
    border-radius: 5px;
    background-color: white; /* Assurez-vous que le fond est visible */
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .modal-image {
    min-width: 100%; /* Assurez-vous que l'image ne dépasse pas le contenu de la modale */
    max-height: 63vh;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
    font-weight: bold;
    cursor: pointer;
  }

  .modal-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .modal-description {
    margin-bottom: 20px;
  }

  // Media query pour les petits écrans (mobiles)
@media (max-width: 768px) {
  .product-display-container {
    flex-direction: column; // Les éléments s'affichent en colonne sur les petits écrans
  }

  .product-card {
    width: 100%; // Chaque carte prend toute la largeur disponible
  }
}

.modal-content {
  width: 45vw;
  height: 70vh;
  padding: 10px;
  border-radius: 5px;
  background-color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-image {
  width: 100%; // Assurez-vous que l'image remplit la largeur de la modale
  height: 100%; // Assurez-vous que l'image remplit la hauteur de la modale
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
}

.modal-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.modal-description {
  margin-bottom: 20px;
}
  

@media screen and (max-width: 768px) {
  .modal-content {
    width: 80vw;
    height: auto;
  }

  .modal-image {
    width: 100%;
    height: auto;
  }
  
}
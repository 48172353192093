.location-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    min-height: 100vh;
    justify-content: center;
    padding-top: 6rem;
    background-image: url('../../assets/images/homePage/zovGlobal.jpg'); /* Chemin vers votre image de fond */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;


    .map-container {
        width: 100%; // ou une autre largeur selon la mise en page
        height: 400px; // ajustez la hauteur comme nécessaire
        margin-top: 1rem;
    
        .google-map {
          width: 100%;
          height: 100%;
          border: none; // Enlève la bordure par défaut de l'iframe
          // Ajoutez des styles supplémentaires si nécessaire
        }
      }
  
    .title {
      font-size: 2rem;
      color: #FFF; // Consider using a color that aligns with your brand's theme
    }
  
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .video-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 2rem 0;
      
        .showroom-video {
          width: 100%;
          max-width: 800px; // Ajustez selon vos besoins
          height: 450px; // Ajustez selon vos besoins
          border: none;
        }
      }
      
  
      .img-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 20px; // This will give space between your images
        align-items: center;
        margin-bottom: 20px;
  
        .showroom-image {
          width: calc(33.333% - 20px); // Adjusts the width according to the gap
          height: 30vh;
          border-radius: 8px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          margin-bottom: 20px;
        }
      }
  
      .info {
        background: white;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        width: 100%; // Adjust the width as per your layout
  
        .info-item {
          display: flex;
          justify-content: center;
          align-items: center;
  
          .icon {
            color: #1c2240; // A luxury-themed color for the icons
            margin-right: 10px;
            transition: transform 0.2s ease-in-out;
  
            &:hover {
              transform: scale(1.1); // Slight zoom effect on hover
              color: #d4af37; // Gold color for a luxurious look on hover
            }
          }
  
          h2 {
            color: #333;
            margin-top: 0;
          }
  
          p {
            color: #666;
            margin-bottom: 10px;
          }

          .contact-link {
            color: #666; // ou toute autre couleur
            text-decoration: none; // pour enlever le soulignement par défaut
          
            &:hover {
              color: #d4af37; // par exemple, une couleur dorée au survol
            }
          }
          
        }
  
        .invitation {
          font-weight: bold;
          color: #1c2240;
          text-align: center;
          margin-top: 30px;
          font-size: 1.1rem;
          border-top: 2px solid #c0a080; // Adds a top border for separation
          padding-top: 20px;
        }
      }
    }

    .hideOnMobile {
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  
    @media screen and (max-width: 768px) {
      .content {
        flex-direction: column; // Passe à une disposition en colonne pour les petits écrans
  
        .img-container {
          width: 100%; // Prend toute la largeur pour les petits écrans
          .showroom-image {
            width: 100%;
          }
        }
  
        .info {
          width: 90%; // Prend toute la largeur pour les petits écrans
          margin-top: 20px; // Ajoute un espace entre les images et les informations
  
          .info-item {
            flex-direction: column; // Pile les icônes et le texte verticalement
  
            .icon {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
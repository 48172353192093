.footer {
  width: 100%;
  background-color: #1c2240;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem;
  box-sizing: border-box;
  font-family: 'Arial', sans-serif;

  .map-container {
    width: 100%;
    height: 300px;
    margin-bottom: 2rem;
    border-radius: 8px;
    overflow: hidden;

    @media screen and (max-width: 768px) {
      height: 200px;
    }

    .google-map {
      width: 100%;
      height: 100%;
    }
  }

  .contact-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    margin-bottom: 2rem;

    .contact-item {
      display: flex;
      align-items: flex-start;
      max-width: 300px;
      gap: 1rem;

      .icon {
        color: #d4af37;
      }

      p {
        margin: 0;
      }

      a {
        color: white;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .social-media {
    display: flex;
    gap: 1.5rem;
    margin-bottom: 2rem;

    a {
      color: #fff;
      transition: color 0.3s;

      &:hover {
        color: #d4af37;
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: 1rem;

    .contact-info {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    .contact-item {
      width: 100%;
      justify-content: center;
      text-align: center;
    }

    .social-media {
      justify-content: center;
    }
  }
}

.home-page {
  font-family: 'Ubuntu', sans-serif;

  .main-banner-carousel {
    .carousel .slide {
      width: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .carousel-slider {
      position: relative;
      text-align: center;
    }

    .slide {
      width: 100%;
      background: #1c2240;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100vw;
        height: 100vh;

        @media (max-width: 768px) {
          width: 820px;
          height: 100vh;
        }
      }
    }
  }

  .carousel-center-text {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 2rem;
    text-align: center;
    z-index: 2;
    max-width: 90%;
    padding: 1rem;
    border-radius: 10px;
    text-shadow: 0 0 8px rgba(0, 0, 0, 0.9);
  }

  .discover-button-wrapper {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;

    @media screen and (max-width: 768px) {
      top: 70%;
      width: 80%;
    }
  }

  .discover-button {
    display: inline-block;
    padding: 0.5rem 2rem;
    font-size: 1rem;
    color: #fff;
    background-color: transparent;
    text-shadow: 0 0 8px rgba(0, 0, 0, 0.9);
    border: 2px solid #fff;
    border-radius: 25px;
    text-decoration: none;
    transition: color 0.3s ease, border-color 0.3s ease;
    box-shadow: 0 0 8px rgba(0, 0, 0, 1.7);

    &:hover {
      background-color: #fff;
      color: #1c2240;
      border-color: #1c2240;
    }

    @media screen and (max-width: 768px) {
      width: 70%;
      margin: 0 auto;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.9);
    }
  }

  // Popup styles
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }

  .popup-content {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    position: relative;
    max-width: 400px;
    width: 90%;
    text-align: center;

    .popup-close {
      position: absolute;
      top: 10px;
      right: 10px;
      background: transparent;
      border: none;
      font-size: 1.2rem;
      cursor: pointer;
    }

    .popup-image {
      max-width: 100%;
      height: auto;
      margin-bottom: 20px;
    }

    .popup-text {
      font-size: 1rem;
      color: #333;
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: 768px) {
    .carousel .control-arrow {
      display: none;
    }
  }
}

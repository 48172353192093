.not-found-container {
    background-image: url('../../assets/images/homePage/zovGlobal.jpg'); // Remplacez par le chemin de votre image
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    .content {
        max-width: 600px;
        background: rgba(255, 255, 255, 0.8); // Un fond légèrement transparent pour une meilleure lisibilité
        padding: 20px;
        border-radius: 10px;

        h1 {
            font-size: 5em;
            color: #333;
        }

        h2 {
            font-size: 2em;
            margin-bottom: 20px;
            color: #555;
        }

        p {
            font-size: 1.2em;
            margin-bottom: 20px;
        }

        button {
            background-color: #444;
            color: white;
            border: none;
            padding: 10px 20px;
            border-radius: 5px;
            cursor: pointer;
            font-size: 1em;
            transition: background-color 0.3s;

            &:hover {
                background-color: #333;
            }
        }
    }
}

// contact.scss

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url('../../assets/images/homePage/zovGlobal.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  color: #fff;

  h1 {
    font-size: 2.5rem;
    margin-bottom: 3rem;
  }

  select, input, textarea {
    padding: 15px;
    margin-bottom: 20px;
    width: 30%; // Ajuster selon la mise en page souhaitée
    border-radius: 8px;
    border: 2px solid #1c2240; // Une couleur de bordure luxueuse
    background-color: rgba(255, 255, 255, 0.8); // Fond légèrement transparent
    font-size: 1rem;
    color: #333;

    &:focus {
      outline: none;
      box-shadow: 0 0 10px #FFF; // Ombre lors de la mise au point
      border-color: #1c2240;
    }
  }

  textarea {
    height: 120px; // Ajuster selon la mise en page souhaitée
  }
  .phone-input-container {
    display: flex;
    justify-content: center;
    width: 50%; // Ajuster selon la mise en page souhaitée

    select {
      border-radius: 8px 0 0 8px; // Arrondi seulement à gauche
      width: 12%;
    }

    input[type="tel"] {
      border-radius: 0 8px 8px 0; // Arrondi seulement à droite
      margin-left: -2px; // Ajuste l'alignement avec le sélecteur
      width: 48%;
    }
  }

  button {
    padding: 15px 30px;
    width: 30%;
    margin-top: 20px;
    border-radius: 8px;
    background-color: #1c2240;
    color: white;
    font-size: 1.1rem;
    font-weight: bold;
    border: 2px solid transparent;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      background-color: transparent;
      color: #FFF;
      border-color: #FFF;
    }
  }
}

@media screen and (max-width: 768px) {
  form {
    padding-top: 2.5rem;
    input, textarea, select, button {
      width: 70%; // Augmente la largeur sur les petits écrans
    }

    .phone-input-container {
      width: 80%;

      select, input[type="tel"] {
        width: 30%;
        border-radius: 8px;
      }

      input[type="tel"] {
        margin-left: 0;
        margin-top: -2px; // Ajuste l'alignement avec le sélecteur
        width: 60%;
      }
    }

    h1 {
      font-size: 2rem;
    }

    p {
      font-size: 1rem;
    }
  }
}

// about.scss
.about {
  color: white;
  .about__top {
    position: relative;
    video.background-video {
      width: 100%;
      height: auto;
    }
  }

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }

  .breadcrumbs {
    margin: 20px 0;
    ul {
      list-style: none;
      padding: 0;
      display: flex;
      align-items: center;
      
      li {
        &:not(:last-child):after {
          content: '>';
          margin: 0 8px;
        }
        
        a {
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }

        span {
          font-weight: bold;
        }
      }
    }
  }

  .about__block {
    margin: 40px 0;
    
    .block {
      &.block_image_right,
      &.block_image_left {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 40px;
        
        .row {
          display: flex;
          width: 100%;
        }

        .columns {
          &.small-12 {
            flex: 0 0 100%;
          }

          &.large-6 {
            flex: 0 0 50%;
          }

          &.large-order-1 {
            order: 1;
          }

          &.large-order-2 {
            order: 2;
          }

          &.block__image {
            background-size: cover;
            background-position: center;
            height: 500px;
          }

          &.block__content {
            padding: 20px;
            display: flex;
            flex-direction: column;
            justify-content: left;
            align-items: center;
            
            .block__title {
              font-size: 24px;
              margin-bottom: 16px;
              text-transform: uppercase;
              font-weight: bold;
            }

            .block__text {
              font-size: 16px;
              line-height: 1.6;
            }
          }
        }
      }
    }
  }

  .about__holder {
    margin: 40px 0;
    
    .about__video {
      iframe {
        border: none;
      }
    }
  }

  .slider {
    margin: 40px 0;

    @media (max-width: 600px) {
      display: none;
    }
    
    .slick-arrow {
      background: none;
      border: none;
      cursor: pointer;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 50px;
      height: 50px;
      z-index: 2;
      font-size: 20px;

      &.slick-prev {
        left: -60px;
      }

      &.slick-next {
        right: -60px;
      }

      .slick-arrow-icon {
        width: 50px;
        height: 50px;
        fill: white;
        transition: fill 0.3s;

        &:hover {
          fill: #ff0000; // Couleur de survol
        }
      }
    }

    .slick-list {
      overflow: hidden;
    }

    .slick-track {
      display: flex;
    }

    .video-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px;
      width: 300px;
      margin: 0 15px;

      &__image {
        position: relative;
        img {
          width: 100%;
          height: auto;
        }

        .play-button {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: rgba(0, 0, 0, 0.5);
          color: white;
          font-size: 24px;
          padding: 10px 20px;
          border-radius: 50%;
          text-decoration: none;
          opacity: 0.7;

          &:hover {
            opacity: 1;
          }
        }
      }

      &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        
        .video-item__length,
        .video-item__title,
        .video-item__description {
          margin-top: 8px;
        }

        .video-item__length {
          font-weight: bold;
        }

        .video-item__title {
          font-size: 18px;
          font-weight: bold;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }

        .video-item__description {
          font-size: 14px;
        }
      }
    }
  }
}

.slick-slide {
  margin: 0 1rem;
}

.block__title {
  font-size: 24px;
  margin-bottom: 16px;
  text-transform: uppercase;
  font-weight: bold;
}
.block__content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;
};

.small-12 {
  align-self: center;
}



.slick-slide {
  margin: 0 1rem;
}

.block__title {
  font-size: 24px;
  margin-bottom: 16px;
  text-transform: uppercase;
  font-weight: bold;
}
.block__content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;
};

.small-12 {
  align-self: center;
}
.privacy-policy {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    padding-top: 6rem;
    color: #FFF;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    margin: 0 auto;
  
    h1 {
      font-size: 24px;
      margin-bottom: 20px;
    }
  
    h2 {
      font-size: 20px;
      margin-top: 20px;
      margin-bottom: 10px;
    }
  
    p {
      margin-bottom: 10px;
    }

    .privacy-policy__link {
      color: #000;
      text-decoration: underline;
    }

    .privacy-policy__link:hover {
      color: #000;
    }

    .privacy-policy__link:visited {
      color: #000;
    }

    .privacy-policy__link:active {
      color: #000;
    }

    .privacy-policy__link:focus {
      color: #000;
    }

    .privacy-policy__link:active {
      color: #000;
    }


    .privacy-policy__link--external {
      color: #000;
      text-decoration: underline;
    }



    .privacy-policy__link--external:hover {
      color: #000;
    }

}
// allConcepts.scss

.allConcepts {
  background-image: url('../../assets/images/homePage/zovGlobal.jpg'); /* Chemin vers votre image de fond */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-bottom: 2rem;
  padding-top: 3rem;
}

@media only screen and (max-width: 600px) {
    .all-concepts-grid {
      .concept-card {
        // Adaptez la taille des cartes pour les petits écrans
        max-width: 100%;
        margin: 0 auto;
  
        .concept-card-action-area {
          // Ajustez la hauteur de l'image pour les petits écrans
          .concept-card-media {
            height: 140px; // Hauteur plus petite pour les mobiles
          }
  
          // Réduire la taille du texte pour les petits écrans
          .concept-title {
            font-size: 1rem;
            padding: 0.5rem;
            text-align: center;
          }
        }
      }
    }
  }
  
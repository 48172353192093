.header {
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1000; // Assurez-vous que le header est au-dessus du carrousel
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    margin-bottom: 2rem;

    .logo {
      max-width: 100px;
      margin-left: 2rem;
    }

    .menu-link {
      text-decoration: none; // Enlève le soulignement
      color: white; // Change la couleur
  
    }
    .navigation {
      a {
        color: white;
        text-shadow: 0 0 8px rgba(0, 0, 0, 0.9);
        text-decoration: none;
        margin-right: 2rem;

        &:hover {
          background-color: #fff;
          color: #1c2240; 
        }
      }
    }
  }
  

  .drawer-menu-item {
    font-size: 1.2rem; /* Taille de la police des éléments de menu */
    padding: 0.5rem 0; /* Espacement vertical pour chaque élément de menu */
    color: white; /* Couleur du texte */
    text-transform: uppercase; /* Style de texte en majuscule */
    transition: background-color 0.3s ease; /* Transition pour l'effet de survol */
  
    &:hover {
      background-color: rgba(255, 255, 255, 0.2); /* Effet de survol */
    }
  }

  @media (max-width: 600px) {
    .header .navigation {
      display: none;
    }
  
    .header .MuiIconButton-edgeStart {
      display: block;
      color: white; /* Ajoutez une couleur si nécessaire */

    }
  }
  
  /* Sur les écrans plus larges, affichez la navigation normale et cachez l'icône hamburger */
  @media (min-width: 601px) {
    .header .MuiIconButton-edgeStart {
      display: none;
    }
  
    .header .navigation {
      display: block;
      color: white; /* Ajoutez une couleur si nécessaire */

    }
  }


    .social-media {
      display: flex;
      align-items: center;
      justify-content: center;
      a {
        color: white; // ou une autre couleur qui se détache de votre image de fond
        margin: 0 1rem;

        &:hover {
          color: #e0e0e0; // couleur au survol
        }
      }
    }

    @media screen and (max-width: 768px) { // Ajustez la largeur selon vos besoins
      .logoMobile {
        margin: 1rem auto;
        width: 60%;
      }
    }

     .MuiMenuItem-root .menu-link {
      text-decoration: none;
      color: #1c2240;
    }